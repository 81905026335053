
import VueCountdown from '@chenfengyuan/vue-countdown';

export default {
    computed: {
        isRTL() {
            return this.$i18n.locale == 'ar'
        }
    },
    components: {
        VueCountdown
    },
    data() {
        const now = new Date();
        const endDate = new Date(now.getFullYear() + 0, 7, -5);

        return {
            time: endDate - now,
        };
    },
};
